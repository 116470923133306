var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "menuItemRow" }, [
        _c(
          "div",
          { staticClass: "menuItem" },
          [
            _c("i", {
              class: _vm.getIcon(_vm.menuItem),
              style: {
                marginLeft: _vm.menuItem.Depth * 2.5 + "rem",
                marginRight: ".5rem",
              },
            }),
            _c(
              "router-link",
              {
                attrs: {
                  to: {
                    name: "EditMenuItem",
                    params: {
                      id: _vm.menuItem.Id,
                      menuItem: _vm.menuItem,
                      reportTypes: _vm.reportTypes,
                    },
                  },
                },
              },
              [_vm._v(" " + _vm._s(_vm.getLabel()) + " ")]
            ),
          ],
          1
        ),
        _c("div", { staticClass: "menuItemType" }, [
          _vm._v(" " + _vm._s(_vm.getType()) + " "),
        ]),
        _c("div", { staticClass: "menuItemGroupedByYear" }, [
          _vm._v(
            " " +
              _vm._s(
                _vm.isDocumentsType(_vm.menuItem)
                  ? _vm.menuItem.isPersistent
                    ? "No"
                    : "Yes"
                  : ""
              ) +
              " "
          ),
        ]),
        _c("div", { staticClass: "menuItemOrder" }, [
          _vm._v(" " + _vm._s(_vm.menuItem.Order) + " "),
        ]),
        _c("div", { staticClass: "menuItemActions" }, [
          _vm.$hasPermissions(_vm.clientSession, ["CLIENT_MENU"], 2)
            ? _c("div", [
                _c(
                  "button",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip.left-start",
                        value: {
                          content: "Move Item Up",
                          container: false,
                          classes: ["tooltip"],
                        },
                        expression:
                          "{ content: 'Move Item Up', container: false, classes: ['tooltip'] }",
                        modifiers: { "left-start": true },
                      },
                    ],
                    on: {
                      click: function ($event) {
                        return _vm.move(-1)
                      },
                    },
                  },
                  [_c("i", { staticClass: "fa fa-arrow-up" })]
                ),
                _c(
                  "button",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip.left-start",
                        value: {
                          content: "Move Item Down",
                          container: false,
                          classes: ["tooltip"],
                        },
                        expression:
                          "{ content: 'Move Item Down', container: false, classes: ['tooltip'] }",
                        modifiers: { "left-start": true },
                      },
                    ],
                    on: {
                      click: function ($event) {
                        return _vm.move(1)
                      },
                    },
                  },
                  [_c("i", { staticClass: "fa fa-arrow-down" })]
                ),
                _c(
                  "button",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip.left-start",
                        value: {
                          content: "Remove Item",
                          container: false,
                          classes: ["tooltip"],
                        },
                        expression:
                          "{ content: 'Remove Item', container: false, classes: ['tooltip'] }",
                        modifiers: { "left-start": true },
                      },
                    ],
                    class: [
                      "trash",
                      !_vm.$hasPermissions(
                        _vm.clientSession,
                        ["CLIENT_MENU"],
                        2
                      )
                        ? "disabled"
                        : "",
                    ],
                    attrs: {
                      disabled: !_vm.$hasPermissions(
                        _vm.clientSession,
                        ["CLIENT_MENU"],
                        2
                      ),
                    },
                    on: { click: _vm.remove },
                  },
                  [_c("i", { staticClass: "fa fa-trash" })]
                ),
              ])
            : _vm._e(),
        ]),
      ]),
      _vm._l(
        _vm.menuItem.children.sort(function (a, b) {
          return parseInt(a.Order) - parseInt(b.Order)
        }),
        function (child) {
          return _c("ClientMenuManagementRow", {
            key: child.Id,
            staticClass: "menu-container",
            attrs: {
              hasChangedOrder: _vm.hasChangedOrder,
              SiteNavigation: _vm.navigation,
              menuItem: child,
              reportTypes: _vm.reportTypes,
            },
            on: {
              SiteNavigationDeleted: _vm.SiteNavigationDeleted,
              SiteNavigationUpdated: _vm.updateSiteNavigation,
            },
          })
        }
      ),
      _vm.showDeleteModal
        ? _c(
            "div",
            { staticClass: "modal is-active", attrs: { id: "delete-modal" } },
            [
              _c("div", {
                staticClass: "modal-background",
                on: {
                  click: function ($event) {
                    _vm.showDeleteModal = false
                  },
                },
              }),
              _c("div", { staticClass: "modal-card" }, [
                _c("header", { staticClass: "modal-card-head has-bg-danger" }, [
                  _c("div", { staticClass: "modal-card-title has-bg-danger" }, [
                    _vm._v("Confirm Delete"),
                  ]),
                  _c("a", {
                    staticClass: "delete",
                    attrs: { "aria-label": "close" },
                    on: {
                      click: function ($event) {
                        _vm.showDeleteModal = false
                      },
                    },
                  }),
                ]),
                _c("section", { staticClass: "modal-card-body" }, [
                  _c("div", { staticClass: "content" }, [
                    _vm._v(' Type "'),
                    _c("b", [_vm._v("delete")]),
                    _vm._v(
                      '" to confirm that you want to delete "' +
                        _vm._s(_vm.getLabel(_vm.menuItem)) +
                        '" '
                    ),
                  ]),
                  _c("div", { staticClass: "field" }, [
                    _c("div", { staticClass: "control" }, [
                      _c("input", {
                        directives: [
                          { name: "focus", rawName: "v-focus" },
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.confirmationText,
                            expression: "confirmationText",
                            modifiers: { trim: true },
                          },
                        ],
                        staticClass: "input",
                        attrs: {
                          type: "text",
                          placeholder: "type `delete` and then click confirm",
                        },
                        domProps: { value: _vm.confirmationText },
                        on: {
                          keydown: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            $event.preventDefault()
                            return _vm.deleteIfConfirmed.apply(null, arguments)
                          },
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.confirmationText = $event.target.value.trim()
                          },
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                    ]),
                  ]),
                ]),
                _c("footer", { staticClass: "modal-card-foot" }, [
                  _c(
                    "a",
                    {
                      staticClass: "button is-danger",
                      attrs: { disabled: _vm.confirmationText !== "delete" },
                      on: { click: _vm.deleteIfConfirmed },
                    },
                    [_vm._m(0), _c("span", [_vm._v("Confirm")])]
                  ),
                  _c(
                    "a",
                    {
                      staticClass: "button",
                      on: {
                        click: function ($event) {
                          _vm.showDeleteModal = false
                        },
                      },
                    },
                    [_vm._v("Cancel")]
                  ),
                ]),
              ]),
            ]
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon" }, [
      _c("i", { staticClass: "fal fa-trash-alt" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }