var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vue-headful", { attrs: { title: _vm.pageTitle } }),
      _c(
        "div",
        {
          staticClass: "has-text-centered has-background-primary",
          staticStyle: { "margin-bottom": "20px" },
        },
        [
          _c(
            "h1",
            {
              staticClass: "is-size-6 has-text-white",
              staticStyle: { padding: "5px 0px" },
            },
            [_vm._v(" " + _vm._s(_vm.pageheading.toLocaleUpperCase()) + " ")]
          ),
        ]
      ),
      _c("div", { staticStyle: { "max-width": "95%", margin: "auto" } }, [
        _vm.isLoading
          ? _c("div", [_c("Loading")], 1)
          : _c("div", { attrs: { id: "body-content-area" } }, [
              _vm.SiteNavigation
                ? _c("div", { attrs: { id: "pagelayout" } }, [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "margin-bottom": "1rem",
                          display: "flex",
                          "justify-content": "flex-end!important",
                        },
                        attrs: { id: "buttongroup" },
                      },
                      [
                        _c("div", { staticClass: "level" }, [
                          _c("div", { staticClass: "level-left" }, [
                            _c("div", { staticClass: "field is-grouped" }, [
                              _vm.selected.length < 2
                                ? _c("div", { staticClass: "control" }, [
                                    _c(
                                      "a",
                                      {
                                        class: [
                                          "button is-accent",
                                          !_vm.$hasPermissions(
                                            _vm.clientSession,
                                            ["CLIENT_MENU"],
                                            2
                                          )
                                            ? "disabled"
                                            : "",
                                        ],
                                      },
                                      [
                                        _vm._m(0),
                                        _c(
                                          "span",
                                          [
                                            _c(
                                              "router-link",
                                              {
                                                staticClass: "has-text-white",
                                                attrs: {
                                                  to: {
                                                    name: "newMenuItem",
                                                    params: {
                                                      menuItem:
                                                        _vm.selected.length ===
                                                        1
                                                          ? _vm.selected[0]
                                                          : {},
                                                      reportTypes:
                                                        _vm.reportTypes,
                                                    },
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.selected.length === 0
                                                        ? "Add Menu Item"
                                                        : "Add Menu Item From Item"
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                              _c(
                                "div",
                                {
                                  staticClass: "control",
                                  staticStyle: { display: "none" },
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      class: [
                                        "button is-dark",
                                        _vm.selected.length === 0
                                          ? "disabled"
                                          : "",
                                      ],
                                      attrs: {
                                        disabled: _vm.selected.length === 0,
                                      },
                                      on: { click: _vm.confirmDelete },
                                    },
                                    [
                                      _vm._m(1),
                                      _c("span", [
                                        _vm._v(
                                          "Delete Report Type" +
                                            _vm._s(
                                              _vm.selected.length > 1 ? "s" : ""
                                            )
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]
                              ),
                              _vm.hasChangedOrder
                                ? _c("div", { staticClass: "control" }, [
                                    _c(
                                      "a",
                                      {
                                        class: ["button is-danger"],
                                        on: { click: _vm.setupSiteNavigation },
                                      },
                                      [
                                        _vm._m(2),
                                        _c("span", [_vm._v("Cancel")]),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                              _vm.hasChangedOrder
                                ? _c("div", { staticClass: "control" }, [
                                    _c(
                                      "a",
                                      {
                                        class: ["button is-accent"],
                                        on: { click: _vm.saveNewOrder },
                                      },
                                      [
                                        _vm._m(3),
                                        _c("span", [_vm._v("Save New Order")]),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                          ]),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "menuItemEditor",
                        staticStyle: { "padding-bottom": "1rem" },
                      },
                      [
                        _vm._m(4),
                        _vm._l(
                          _vm.SiteNavigation.filter(function (item) {
                            return parseInt(item.Depth) === 0
                          }),
                          function (menuItem) {
                            return _c("ClientMenuManagementRow", {
                              key: menuItem.Id,
                              staticClass: "menu-container",
                              attrs: {
                                hasChangedOrder: _vm.hasChangedOrder,
                                SiteNavigation: _vm.SiteNavigation,
                                menuItem: menuItem,
                                reportTypes: _vm.reportTypes,
                              },
                              on: {
                                SiteNavigationDeleted:
                                  _vm.SiteNavigationDeleted,
                                SiteNavigationUpdated: _vm.updateSiteNavigation,
                              },
                            })
                          }
                        ),
                      ],
                      2
                    ),
                  ])
                : _vm._e(),
            ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon" }, [
      _c("i", { staticClass: "fal fa-plus-square" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon" }, [
      _c("i", { staticClass: "fal fa-trash-alt" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon" }, [
      _c("i", { staticClass: "fal fa-times" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon" }, [
      _c("i", { staticClass: "fal fa-check" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "menu-container" }, [
      _c("div", { staticClass: "menuItemRow menuItemRow--header" }, [
        _c("div", { staticClass: "menuItem cms" }, [_vm._v(" Label ")]),
        _c("div", { staticClass: "menuItemType cms" }, [_vm._v(" ItemType ")]),
        _c("div", { staticClass: "menuItemGroupedByYear cms" }, [
          _vm._v(" Grouped By Year "),
        ]),
        _c("div", { staticClass: "menuItemOrder cms" }, [_vm._v(" Order ")]),
        _c("div", { staticClass: "menuItemActions cms" }, [
          _vm._v(" Actions "),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }